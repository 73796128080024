<template>
  <q-no-ssr>
    <div class="relative-position">
      <header-btn-template :icon="t('icons.cart')" :to="{ name: 'cart' }" />
      <q-badge color="dark" :label="totalQuantity" floating />
    </div>

    <template v-slot:placeholder>
      <header-btn-template :icon="t('icons.cart')" :to="{ name: 'cart' }" />
    </template>
  </q-no-ssr>
</template>

<script setup>
import { i18n } from "src/boot/i18n";
import { storeToRefs } from "pinia";
import { useCartStore } from "src/stores/cart";
import HeaderBtnTemplate from "src/components/header/HeaderBtnTemplate.vue";

defineOptions({ name: "CartHeaderBtn" });

const { t } = i18n.global;
const cartStore = useCartStore();
const { totalQuantity } = storeToRefs(cartStore);
</script>
