<template>
  <q-toolbar class="header-height">
    <image-aura-clone class="absolute-full" :z-index="-1" />
    <search-header-btn v-if="$q.screen.gt.sm" />
    <menu-header-btn v-else />
    <q-toolbar-title class="q-pt-sm relative-position text-center">
      <logo-header :height="36" />
    </q-toolbar-title>
    <cart-header-btn />
  </q-toolbar>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useLayoutStore } from "src/stores/layout";
import CartHeaderBtn from "components/cart/CartHeaderBtn.vue";
import ImageAuraClone from "components/image/ImageAuraClone.vue";
import LogoHeader from "components/logo/LogoHeader.vue";
import MenuHeaderBtn from "components/menu/MenuHeaderBtn.vue";
import SearchHeaderBtn from "src/components/search/SearchHeaderBtn.vue";

defineOptions({ name: "HeaderToolbar" });

const layoutStore = useLayoutStore();
const { headerReveal } = storeToRefs(layoutStore);
</script>
