<template>
  <header-btn-template :icon="t('icons.search')" @click="onClick()" />
</template>

<script setup>
import { useDialogStore } from "src/stores/dialog";
import { i18n } from "src/boot/i18n";
import HeaderBtnTemplate from "src/components/header/HeaderBtnTemplate.vue";

defineOptions({ name: "SearchHeaderBtn" });

const { t } = i18n.global;
const dialogStore = useDialogStore();

const onClick = () => {
  dialogStore.show("search");
};
</script>
