<template>
  <div>
    <div v-if="transformedMenuItems?.length" class="justify-center row">
      <div class="col-12 column text-center">
        <logo-moon-boobs class="q-mt-xl" :color="textColor" :height="42" />
        <logo-type class="q-my-lg" :color="textColor" :height="42" />
      </div>
      <div class="items-center justify-center row">
        <div
          class="q-mt-md"
          id="omnisend-embedded-v2-640bb4b1226d07b48dd581fc"
        ></div>
      </div>
      <div class="justify-center items center q-mt-md row">
        <q-btn
          v-for="item in transformedMenuItems"
          :text-color="fillColor"
          :key="item.id"
          :label="item.title"
          no-caps
          :to="item.to"
        />
      </div>
    </div>
    <div :class="textColor" class="q-mb-md q-mt-lg text-caption text-center">
      {{ t("footer.copyright") }}
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import { useFooterStore } from "src/stores/footer";
import LogoType from "src/components/logo/LogoType.vue";
import LogoMoonBoobs from "src/components/logo/LogoMoonBoobs.vue";

defineOptions({ name: "FooterNavigation" });

const { t } = i18n.global;
const colorStore = useColorStore();
const { fillColor, textColor } = storeToRefs(colorStore);
const footerStore = useFooterStore();
const { transformedMenuItems } = storeToRefs(footerStore);
</script>
