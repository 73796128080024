<template>
  <q-dialog ref="dialogRef" @hide="onHide">
    <div
      class="column full-height overflow-visible relative-position"
      style="min-width: calc(80vw + 32px)"
    >
      <product-variant :product="product">
        <template #default="{ variants }">
          <q-layout class="overflow-visible" container view="hHh lpR fFf">
            <q-page-container class="overflow-visible">
              <image-aura-clone
                v-if="!$q.platform.is.mobile"
                class="absolute-full"
              />
              <q-page style="overflow-x: visible">
                <q-page-sticky
                  v-if="$q.screen.gt.sm"
                  class="column full-height"
                  position="top-left"
                  :style="`width: ${swiperSlideWidthLgPadding}px`"
                >
                  <swiper-component
                    class="absolute-full flex flex-center"
                    effect="coverflow"
                    :id="auraId"
                    next="product-dialog-next"
                    prev="product-dialog-prev"
                    :slides="product.images.edges"
                    :slides-to="toRoute"
                    :slide-width="
                      $q.screen.lt.md
                        ? '80vw'
                        : `${swiperSlideWidthLgPadding}px`
                    "
                    @slide-change="(slide) => onSlideChange(slide)"
                  />
                </q-page-sticky>
                <q-scroll-area
                  :class="[`absolute-${$q.screen.gt.sm ? 'right' : 'full'}`]"
                  :style="
                    $q.screen.gt.sm
                      ? `width: calc((80vw + 32px) - ${swiperSlideWidthLgPadding}px)`
                      : '80vw;'
                  "
                >
                  <div
                    v-if="$q.screen.lt.md"
                    class="relative-position"
                    style="height: calc((80vw + 32px) / 0.6667)"
                  >
                    <swiper-component
                      class="absolute-full"
                      effect="coverflow"
                      :id="auraId"
                      :overflow="false"
                      :slides="product.images.edges"
                      :slides-to="toRoute"
                      :slide-width="`${$q.screen.width - screenRatio + 32}px`"
                      style="z-index: 0"
                      @slide-change="(slide) => onSlideChange(slide)"
                    />
                  </div>
                  <div class="column full-height relative-position">
                    <image-aura-clone
                      style="margin-left: 16px; margin-right: 16px; z-index: -1"
                    />
                    <product-content
                      class="q-mx-md"
                      :details-border-left="false"
                      dialog
                      :product="product"
                      :swiper-width="
                        $q.screen.gt.sm
                          ? `calc(80vw - ${swiperSlideWidthLg + 48}px)`
                          : '80vw'
                      "
                      :variants="variants"
                      :variants-border-left="false"
                      :variants-border-right="false"
                    />
                  </div>
                </q-scroll-area>
              </q-page>
            </q-page-container>
          </q-layout>
        </template>
      </product-variant>
      <div class="absolute-top-right q-pr-md">
        <q-btn
          v-close-popup
          dense
          color="primary"
          square
          :text-color="fillColor"
          :icon="t('icons.close')"
        />
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import { useAuraStore } from "src/stores/aura";
import { useColorStore } from "src/stores/color";
import ImageAuraClone from "src/components/image/ImageAuraClone.vue";
import ProductContent from "./ProductContent.vue";
import ProductVariant from "./ProductVariant.vue";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";

defineOptions({ name: "ProductPreviewDialog" });
const props = defineProps({
  data: { type: Object, default: () => {} },
});

defineEmits([...useDialogPluginComponent.emits]);
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const $q = useQuasar();
const { t } = i18n.global;
const auraStore = useAuraStore();
const { aura } = storeToRefs(auraStore);
const colorStore = useColorStore();
const { fillColor } = storeToRefs(colorStore);
const auraId = ref("productDialogSwiper");
const swiperSlide = ref({});
const screenRatio = computed(() => $q.screen.width * 0.2);
const swiperHeightLg = computed(() => $q.screen.height - 58);
const swiperSlideWidthLg = computed(() => swiperHeightLg.value * 0.6667);
const swiperSlideWidthLgPadding = computed(() =>
  Math.round(swiperSlideWidthLg.value - 32)
);
const product = computed(() => props.data.data?.product);
const toRoute = computed(() => {
  return {
    name: "product",
    params: { product: product.value.handle },
  };
});

const onHide = () => {
  onDialogHide();
};

const onOKClick = () => {
  onDialogOK();
};

const onSlideChange = (slide) => {
  aura.value = slide.node || slide;
  swiperSlide.value = slide;
};
</script>
