<template>
  <q-layout
    view="lhr LpR lfr"
    @resize="(size) => onResize(size)"
    @scroll="(details) => onScroll(details)"
  >
    <image-aura-background class="fixed-full" set-palette />
    <q-header
      bordered
      class="bg-primary"
      :height-hint="56"
      ref="headerRef"
      reveal
      @reveal="(value) => onReveal(value)"
    >
      <header-toolbar />
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      :behavior="$q.screen.lt.md ? 'mobile' : 'desktop'"
      bordered
      :class="drawerClass"
      persistent
      show-if-above
      side="left"
    >
      <menu-navigation />
    </q-drawer>
    <q-drawer
      v-model="rightDrawerOpen"
      :behavior="$q.screen.lt.md ? 'mobile' : 'desktop'"
      bordered
      class="relative-position"
      :class="drawerClass"
      persistent
      show-if-above
      side="right"
    >
      <Suspense>
        <template #default>
          <DrawerLayout />
        </template>
        <template #fallback>
          <q-inner-loading dark showing>
            <q-spinner-tail size="50%" color="primary" />
          </q-inner-loading>
        </template>
      </Suspense>
    </q-drawer>
    <q-page-container class="no-scroll">
      <router-view />
    </q-page-container>
    <q-footer bordered class="bg-transparent">
      <blur-background blur="xl" />
      <footer-navigation class="relative-position" />
    </q-footer>
    <dialog-switch />
    <toast-background />
    <toast-switch />
  </q-layout>
</template>

<script setup>
import { computed, defineAsyncComponent, nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { useLayoutStore } from "src/stores/layout";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import DialogSwitch from "src/components/dialog/DialogSwitch.vue";
import DrawerLayout from "./DrawerLayout.vue";
import HeaderToolbar from "src/components/header/HeaderToolbar.vue";
import ImageAuraBackground from "src/components/image/ImageAuraBackground.vue";
import FooterNavigation from "src/components/footer/FooterNavigation.vue";
import MenuNavigation from "src/components/menu/MenuNavigation.vue";
const ToastBackground = defineAsyncComponent(() =>
  import("src/components/toast/ToastBackground.vue")
);
const ToastSwitch = defineAsyncComponent(() =>
  import("src/components/toast/ToastSwitch.vue")
);

defineOptions({ name: "MainLayout" });

const $q = useQuasar();
const layoutStore = useLayoutStore();
const {
  headerHeight,
  headerReveal,
  leftDrawerOpen,
  mounted,
  rightDrawerOpen,
  scrollDetails,
  scrollPosition,
  scrollTop,
  sizeDetails,
} = storeToRefs(layoutStore);

const headerRef = ref(null);

const drawerClass = computed(() =>
  $q.screen.lt.md ? "bg-primary" : "bg-transparent"
);

const onResize = (size) => {
  sizeDetails.value = size;
};

const onReveal = (value) => {
  headerReveal.value = value;
};

const onScroll = (details) => {
  scrollTop.value = details.position === 0;
  scrollDetails.value = details;
  scrollPosition.value = details.position;
};

onMounted(() => {
  headerHeight.value = headerRef.value.$el.clientHeight;
  nextTick(() => {
    mounted.value = true;
  });
});
</script>
