<template>
  <header-btn-template
    :icon="t('icons.menu')"
    @click="leftDrawerOpen = !leftDrawerOpen"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useLayoutStore } from "src/stores/layout";
import HeaderBtnTemplate from "../header/HeaderBtnTemplate.vue";

defineOptions({ name: "MenuHeaderBtn" });

const layoutStore = useLayoutStore();
const { leftDrawerOpen } = storeToRefs(layoutStore);
const { t } = i18n.global;
</script>
