import { setCssVar, colors } from "quasar";
import { useColorStore } from "src/stores/color";

const hexToRgbString = (hex) => {
  const rgb = colors.hexToRgb(hex);
  return `${rgb.r}, ${rgb.g}, ${rgb.b}`;
};

const usePaletteColors = () => {
  const setPaletteColors = (palette, isLight) => {
    if (!palette) return;

    const commonPaletteVars = {
      "dark-vibrant": palette.darkVibrant?.background || "#1d1d1d",
      "light-muted": palette.lightMuted?.background || "#ffffff",
      vibrant: palette.vibrant?.background || "#1d1d1d",
      "dark-muted": palette.darkMuted?.background || "#1d1d1d",
      muted: palette.muted?.background || "#ffffff",
      "light-vibrant": palette.lightVibrant?.background || "#ffffff",
    };

    if (palette.additionalColors) {
      palette.additionalColors.forEach((color, index) => {
        const key = `additional-color-${index + 1}`;
        commonPaletteVars[key] = color.background || "#1d1d1d";
      });
    }

    const lightModePaletteVars = {
      primary:
        commonPaletteVars["additional-color-3"] ||
        commonPaletteVars["light-vibrant"],
      "primary-rgb": hexToRgbString(
        commonPaletteVars["additional-color-3"] ||
          commonPaletteVars["light-vibrant"]
      ),
      secondary: commonPaletteVars["dark-vibrant"],
      "secondary-rgb": hexToRgbString(commonPaletteVars["dark-vibrant"]),
      accent: commonPaletteVars["light-muted"],
      "accent-rgb": hexToRgbString(commonPaletteVars["light-muted"]),
      // "swiper-navigation-color": commonPaletteVars["dark-vibrant"],
      // "swiper-pagination-color": commonPaletteVars["dark-vibrant"],
      "swiper-navigation-color": "#1d1d1d",
      "swiper-pagination-color": "#1d1d1d",
    };

    const darkModePaletteVars = {
      primary: commonPaletteVars["dark-vibrant"],
      "primary-rgb": hexToRgbString(commonPaletteVars["dark-vibrant"]),
      secondary: commonPaletteVars["vibrant"],
      "secondary-rgb": hexToRgbString(commonPaletteVars["vibrant"]),
      accent: commonPaletteVars["muted"],
      "accent-rgb": hexToRgbString(commonPaletteVars["muted"]),
      // "swiper-navigation-color": commonPaletteVars["vibrant"],
      // "swiper-pagination-color": commonPaletteVars["vibrant"],
      "swiper-navigation-color": "#ffffff",
      "swiper-pagination-color": "#ffffff",
    };

    const paletteVars = isLight ? lightModePaletteVars : darkModePaletteVars;

    Object.keys(commonPaletteVars).forEach((key) => {
      document.documentElement.style.setProperty(
        `--${key}`,
        commonPaletteVars[key]
      );
    });

    Object.keys(paletteVars).forEach((key) => {
      document.documentElement.style.setProperty(`--${key}`, paletteVars[key]);
    });

    document.documentElement.style.setProperty(
      "--swiper-navigation-color",
      paletteVars["swiper-navigation-color"]
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      paletteVars["swiper-pagination-color"]
    );

    setCssVar("primary", paletteVars.primary);
    setCssVar("primary-rgb", paletteVars["primary-rgb"]);
    setCssVar("secondary", paletteVars.secondary);
    setCssVar("secondary-rgb", paletteVars["secondary-rgb"]);
    setCssVar("accent", paletteVars.accent);
    setCssVar("accent-rgb", paletteVars["accent-rgb"]);

    const colorStore = useColorStore();
    colorStore.isLight = isLight;
    colorStore.palette = { ...paletteVars };
  };

  return { setPaletteColors };
};

export default usePaletteColors;
