<template>
  <div class="full-height relative-position">
    <image-aura-clone class="mask-overflow-b" />
    <circle-text-header class="header-height" :header="t('menu.label')" />
    <q-separator />
    <q-list v-if="transformedMenuItems?.length" role="list">
      <q-expansion-item
        active-class="text-weight-regular"
        :dense="!$q.platform.is.mobile"
        :expand-icon="t('icons.arrowDown')"
        :expand-icon-class="$q.dark.isActive ? 'text-white' : 'text-dark'"
        header-class="text-h6 text-letterspace text-weight-thin"
        hide-expand-icon
        :to="{ name: 'search' }"
        @click="onSearchClick"
      >
        <template #header="{}">
          <q-item-section> Search </q-item-section>
        </template>
      </q-expansion-item>
      <template v-for="menu in transformedMenuItems" :key="menu.slug">
        <q-expansion-item
          v-model="menuNavigationModel[menu.slug]"
          active-class="text-weight-regular"
          :dense="!$q.platform.is.mobile"
          :expand-icon="t('icons.arrowDown')"
          :expand-icon-class="$q.dark.isActive ? 'text-white' : 'text-dark'"
          group="menuNavigation"
          header-class="text-h6 text-letterspace text-weight-thin"
          :hide-expand-icon="!menu.items.length"
          :label="menu.title"
          :to="menu.toRoute"
          @before-hide="onBeforeHide"
          @before-show="onBeforeShow"
          @click="onClick()"
        >
          <template #header="{}">
            <q-item-section>
              {{ menu.title }}
            </q-item-section>
          </template>
          <q-card v-if="menu.items.length" class="bg-transparent">
            <q-card-section>
              <q-list :dense="!$q.platform.is.mobile" role="list">
                <blur-background blur="xl" />
                <q-item
                  v-for="link in menu.items"
                  :key="link.slug"
                  active-class="text-h6 text-letterspace text-weight-thin"
                  replace
                  :to="link.toRoute"
                  @click="$q.screen.lt.md ? (leftDrawerOpen = false) : void 0"
                >
                  <q-item-section>
                    <q-item-label> {{ link.title }} </q-item-label>
                  </q-item-section>
                  <q-item-section avatar v-if="isActiveLink(link.toRoute)">
                    <q-icon :name="t('icons.handRight')" size="xl" />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-separator v-if="menuNavigationModel[menu.slug]" />
      </template>
      <q-item v-ripple clickable>
        <q-item-section>
          <q-icon
            :color="$q.dark.isActive ? 'white' : 'dark'"
            :name="sharpVolumeOff"
            size="sm"
          />
        </q-item-section>
        <q-item-section avatar>
          <q-toggle
            v-model="reducedSound"
            :color="$q.dark.isActive ? 'white' : 'dark'"
          />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script setup>
import { inject, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import {
  sharpVolumeOff,
  sharpVolumeUp,
} from "@quasar/extras/material-icons-sharp";
import { i18n } from "src/boot/i18n";
import { useMenuData } from "src/composables/menu/useMenuData";
import { useLayoutStore } from "src/stores/layout";
import { useMenuStore } from "src/stores/menu";
import { usePreferencesStore } from "src/stores/preferences";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import CircleTextHeader from "src/components/circleText/CircleTextHeader.vue";
import ImageAuraClone from "src/components/image/ImageAuraClone.vue";

defineOptions({ name: "MenuNavigation" });

const $q = useQuasar();
const bus = inject("bus");
const { t } = i18n.global;
const route = useRoute();
const router = useRouter();
const layoutStore = useLayoutStore();
const { leftDrawerOpen } = storeToRefs(layoutStore);
const menuStore = useMenuStore();
const { menuNavigation, menuNavigationModel } = storeToRefs(menuStore);
const preferencesStore = usePreferencesStore();
const { reducedSound } = storeToRefs(preferencesStore);

const { transformedMenuItems } = useMenuData(menuNavigation.value);

const isActiveLink = (toRoute) => {
  const resolved = router.resolve(toRoute);
  return route.fullPath === resolved.href;
};

const onBeforeHide = function () {
  bus.emit("mainMenuAccordionCloseSound");
};

const onBeforeShow = function () {
  bus.emit("mainMenuAccordionOpenSound");
};

const onClick = () => {
  bus.emit("menuSelectSound");
};

const onSearchClick = () => {
  if ($q.screen.lt.md) leftDrawerOpen.value = false;
  onClick();
};

watch(
  () => route.params,
  async () => {
    const path = route.fullPath.split("/");
    path[1].startsWith("search?") ? (path[1] = "search") : void 0;
    menuStore.updateMenuNavigationModel(path[1]);
  },
  { immediate: true }
);
</script>
