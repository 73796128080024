<template>
  <image-aura
    v-if="computedAura"
    :classes="classes"
    :image="computedAura"
    :set-clone="setPalette"
    @brightness-change="(result) => onBrightnessChange(result)"
  />
</template>

<script setup>
import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import usePaletteColors from "src/composables/color/usePaletteColors";
import { useAuraStore } from "src/stores/aura";
import ImageAura from "./ImageAura.vue";

defineOptions({ name: "ImageAuraBackground" });

const props = defineProps({
  classes: { type: [Array, String, Boolean], default: false },
  setPalette: { type: Boolean, default: false },
});

const $q = useQuasar();
const bus = inject("bus");
const { setPaletteColors } = usePaletteColors();
const auraStore = useAuraStore();
const { aura } = storeToRefs(auraStore);

const computedAura = computed(() => aura.value);

const onBrightnessChange = (result) => {
  if (!props.setPalette) return;
  // console.log(result);
  const isLight = result.level === "light";
  setPaletteColors(result.palette, isLight);
  bus.emit("shimmerChimeSound");
};
</script>
