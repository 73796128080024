<template>
  <q-layout view="hHh lpR fFf">
    <q-drawer
      v-model="rightChildDrawerOpen"
      side="right"
      bordered
      class="bg-primary no-scroll"
      @before-hide="onBeforeHide()"
      @before-show="onBeforeShow()"
    >
      <q-scroll-area class="fit">
        <filter-drawer
          v-if="
            $route?.name &&
            ($route.name.endsWith('CollectionProduct') ||
              $route.name === 'home')
          "
          view="drawer"
        />
      </q-scroll-area>
    </q-drawer>
    <q-page-container>
      <image-aura-clone class="flip-horizontal" />
      <blur-background blur="xl" style="opacity: 0.4" />
      <q-scroll-area
        class="fit window-height"
        ref="scrollAreaRef"
        style="height: 100%"
        :thumb-style="{ zIndex: 3 }"
        visible
      >
        <router-view name="drawer" />
      </q-scroll-area>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { inject, nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useLayoutStore } from "../stores/layout";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import FilterDrawer from "src/components/filter/FilterDrawer.vue";
import ImageAuraClone from "src/components/image/ImageAuraClone.vue";

defineOptions({ name: "DrawerLayout" });

const bus = inject("bus");
const router = useRouter();
const layoutStore = useLayoutStore();
const { rightChildDrawerOpen } = storeToRefs(layoutStore);
const scrollAreaRef = ref(false);

const onBeforeHide = function () {
  bus.emit("mainMenuAccordionCloseSound");
};

const onBeforeShow = function () {
  bus.emit("mainMenuAccordionOpenSound");
};

watch(
  () => router.currentRoute.value,
  (newRoute) => {
    if (newRoute.meta.scrollToTop) {
      nextTick(() => {
        scrollAreaRef.value.setScrollPosition("vertical", 0);
      });
    }
  }
);
</script>
