<template>
  <q-dialog
    ref="dialogRef"
    maximized
    @hide="onDialogHide"
    @before-show="onBeforeShow()"
  >
    <div
      class="fit flex flex-center relative-position"
      v-touch-pan.prevent.mouse="onPan"
    >
      <q-img
        :alt="currentImage.node.alt || 'image'"
        class="fullscreen"
        :src="currentImage.node.xx"
        :style="imageStyle"
      >
        <template #loading>
          <q-spinner-tail color="primary" size="42%" />
        </template>
      </q-img>
      <q-responsive
        class="fixed-top-right q-ma-md z-top"
        :ratio="1"
        style="width: 56px"
      >
        <blur-button
          :icon="t('icons.zoomOut')"
          mask="oval"
          :size="btnSize"
          @click="onHide()"
        />
      </q-responsive>
      <div class="fixed-right q-mr-md" style="top: calc(50% - 23px)">
        <blur-button
          class="next-btn"
          :icon="t('icons.arrowNext')"
          :size="btnSize"
          @click="onNextClick()"
        />
      </div>
      <div class="fixed-left q-ml-md" style="top: calc(50% - 23px)">
        <blur-button
          class="prev-btn"
          :icon="t('icons.arrowPrev')"
          :size="btnSize"
          @click="onPrevClick()"
        />
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { i18n } from "src/boot/i18n";
import { useLayoutStore } from "src/stores/layout";
import BlurButton from "src/components/blur/BlurButton.vue";

defineOptions({ name: "ImageZoomDialog" });

const props = defineProps({
  data: { type: Object, default: () => {} },
  btnSize: { type: String, default: "md" },
});

defineEmits([...useDialogPluginComponent.emits]);

const { t } = i18n.global;
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();
const layoutStore = useLayoutStore();
const { zoomEnabled } = storeToRefs(layoutStore);
const currentIndex = ref(props.data.data.index);
const images = computed(() => props.data.data.images);
const currentImage = computed(() => images.value[currentIndex.value]);
const translateX = ref(0);
const translateY = ref(0);
const imageStyle = computed(() => {
  return {
    transform: `translate(${translateX.value}px, ${translateY.value}px)`,
  };
});

const onBeforeShow = () => {
  zoomEnabled.value = true;
};

const onOKClick = () => {
  onDialogOK();
};

const onHide = () => {
  zoomEnabled.value = false;
  onDialogHide();
};

const onNextClick = () => {
  if (currentIndex.value < images.value.length - 1) {
    currentIndex.value++;
  } else {
    currentIndex.value = 0;
  }
  resetImagePosition();
};

const onPrevClick = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  } else {
    currentIndex.value = images.value.length - 1;
  }
  resetImagePosition();
};

const onPan = (event) => {
  translateX.value += event.delta.x;
  translateY.value += event.delta.y;
};

const resetImagePosition = () => {
  translateX.value = 0;
  translateY.value = 0;
};

onMounted(() => {
  resetImagePosition();
});
</script>
