<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <q-card class="bg-primary q-dialog-plugin">
      <q-card-section class="row items-center">
        <span class="q-ml-sm">Remove this item from your cart?</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="Cancel"
          color="dark"
          v-close-popup
          @click="onCancel()"
        />
        <q-btn label="Remove" color="dark" v-close-popup @click="onRemove()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { useCartStore } from "src/stores/cart";

defineOptions({ name: "DialogTemplate" });

const props = defineProps({
  data: { type: Object, default: () => {} },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const cartStore = useCartStore();
const { removeLineItem, setLineItemQuantityOne } = storeToRefs(cartStore);

const onCancel = () => {
  setLineItemQuantityOne.value = props.data.data.lineItem;
  onOKClick();
};

const onOKClick = () => {
  onDialogOK();
};

const onRemove = () => {
  removeLineItem.value = props.data.data.lineItem;
  onOKClick();
};
</script>
