<template>
  <router-link aria-label="home" :to="{ path: '/' }">
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      mode="out-in"
    >
      <logo-type
        v-if="scrollTop && !$q.platform.is.mobile"
        :color="textColor"
        :height="height"
        key="logotype"
      />
      <logo-moon-boobs
        v-else
        :color="textColor"
        :height="height"
        key="moonboobs"
      />
    </transition>
  </router-link>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import { useLayoutStore } from "src/stores/layout";
import LogoMoonBoobs from "./LogoMoonBoobs.vue";
import LogoType from "./LogoType.vue";

defineOptions({ name: "LogoHeader" });

const props = defineProps({
  height: { type: [Boolean, Number], default: false },
});

const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const layoutStore = useLayoutStore();
const { scrollTop } = storeToRefs(layoutStore);
</script>
