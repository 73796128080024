<template>
  <q-dialog ref="dialogRef" @hide="onHide()" @show="onShow()">
    <q-card bordered class="q-dialog-plugin shadow-11">
      <blur-mask mask="xy" />
      <q-card-section class="q-pa-none">
        <search-input type="dialog" />
      </q-card-section>
      <q-card-section class="q-pa-none">
        <predictive-search-results
          :data="predictiveDialogResults"
          type="dialog"
        />
      </q-card-section>
      <q-card-actions align="center">
        <q-btn color="dark" label="More Results" no-caps @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { queryPredictiveSearch } from "src/api/shopify/queries/predictiveSearch";
import { useQueryErrorHandler } from "src/composables/query/useQueryErrorHandler";
import { useUpdateStoreFromQuery } from "src/composables/query/useUpdateStoreFromQuery";
import { useLayoutStore } from "src/stores/layout";
import { usePredictiveSearchStore } from "src/stores/predictiveSearch";
import { getQueryKey } from "src/utils/global";
import BlurMask from "src/components/blur/BlurMask.vue";
import SearchInput from "./SearchInput.vue";
import PredictiveSearchResults from "./predictive/PredictiveSearchResults.vue";

const QKEY = "SearchDialog";

defineOptions({ name: QKEY });

defineEmits([...useDialogPluginComponent.emits]);

const props = defineProps({
  data: { type: Object, default: () => ({}) },
});

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const router = useRouter();

const layoutStore = useLayoutStore();
const { colorBackgroundFade } = storeToRefs(layoutStore);
const predictiveSearchStore = usePredictiveSearchStore();
const { pageQueryTypes, predictiveDialogResults, dialogTerm } = storeToRefs(
  predictiveSearchStore
);

const queryKey = computed(() => [
  dialogTerm.value,
  pageQueryTypes.value,
  ...getQueryKey(QKEY, router.currentRoute.value),
]);

const {
  data: searchData,
  error,
  isError,
  isFetching,
  isLoading,
  isPending,
  refetch,
} = useQuery({
  queryKey: queryKey.value,
  queryFn: () =>
    queryPredictiveSearch({
      query: dialogTerm.value,
      types: pageQueryTypes.value,
    }),
  initialData: () => predictiveDialogResults.value,
  placeholderData: keepPreviousData,
  enabled: true,
});

const onHide = () => {
  onDialogHide();
};

const onShow = () => {};

const onOKClick = () => {
  onDialogOK();
  toSearchPage();
};

const toSearchPage = () => {
  router.push({
    name: "search",
    query: { term: dialogTerm.value },
  });
};

// watch(
//   dialogTerm,
//   (term) => {
//     console.log(term);
//   },
//   { immediate: true }
// );

watch(
  queryKey,
  () => {
    refetch();
  },
  { deep: true }
);

useQueryErrorHandler(error, isError);
useUpdateStoreFromQuery(searchData, predictiveDialogResults);
</script>
