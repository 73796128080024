import { computed } from "vue";

export function useFooterMenuData(menuItems) {
  const transformedMenuItems = computed(() => {
    return menuItems.map((menuItem) => {
      let routeParams = {};
      if (menuItem.type === "COLLECTION") {
        routeParams = {
          name: "collection",
          params: { collection: menuItem.url.split("/").pop() },
        };
      } else if (menuItem.type === "PAGE") {
        const slug = menuItem.url.split("/").pop();
        switch (slug) {
          case "contact-us":
            routeParams = {
              name: "page",
              params: { page: "about" },
            };
            break;
          case "contact":
            routeParams = { name: "contact" };
            break;
          case "faqs":
            routeParams = { name: "faqs" };
            break;
          default:
            routeParams = {
              name: "page",
              params: { page: slug },
            };
            break;
        }
      } else if (menuItem.type === "BLOG") {
        routeParams = {
          name: "blog",
          params: { blog: menuItem.url.split("/").pop() },
        };
      } else if (menuItem.type === "ARTICLE") {
        const urlSegments = menuItem.url.split("/");
        routeParams = {
          name: "blogArticle",
          params: { blog: urlSegments[2], article: urlSegments[3] },
        };
      } else {
        const slug = menuItem.url.split("/").pop();
        if (menuItem.type === "SHOP_POLICY") {
          routeParams = {
            name: "page",
            params: { page: slug },
          };
        } else {
          routeParams = { path: menuItem.url };
        }
      }
      return {
        ...menuItem,
        to: routeParams,
      };
    });
  });

  return {
    transformedMenuItems,
  };
}
