<template>
  <q-btn
    :aria-label="ariaLabel"
    :color="fillColor"
    flat
    :icon="icon"
    round
    @click="onClick()"
  />
</template>

<script setup>
import { inject } from "vue";
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";

defineOptions({ name: "HeaderBtnTemplate" });

const props = defineProps({
  ariaLabel: { type: String, default: "menu " },
  icon: { type: String, default: "stat_0" },
});

const bus = inject("bus");
const colorStore = useColorStore();
const { fillColor } = storeToRefs(colorStore);

const onClick = () => {
  bus.emit("showHideSounds");
};
</script>
