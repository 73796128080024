import { computed } from "vue";
import { defineStore } from "pinia";
import { useShopStore } from "./shop";
import { useFooterMenuData } from "src/composables/menu/useFooterMenuData";

export const useFooterStore = defineStore("footer", () => {
  const shopStore = useShopStore();
  const footerNavigation = computed(
    () => shopStore.shop?.footerMenu?.items || []
  );
  const { transformedMenuItems } = useFooterMenuData(footerNavigation.value);

  return {
    footerNavigation,
    transformedMenuItems,
  };
});
