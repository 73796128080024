<template>
  <div />
</template>

<script setup>
import { ref, shallowRef, watch } from "vue";
import { useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { useDialogStore } from "src/stores/dialog";
import CartConfirmRemoveDialog from "src/components/cart/CartConfirmRemoveDialog.vue";
import DialogTemplate from "./DialogTemplate.vue";
import ImageZoomDialog from "src/components/image/ImageZoomDialog.vue";
import ProductPreviewDialog from "src/components/product/ProductPreviewDialog.vue";
import SearchDialog from "src/components/search/SearchDialog.vue";

const $q = useQuasar();
const dialogStore = useDialogStore();
const { dialogs } = storeToRefs(dialogStore);
const dialogComponent = shallowRef(null);
const dialogCount = ref(0);

defineOptions({ name: "DialogSwitch" });

const show = (data) => {
  if (data) {
    switchDialog(data);
  }
};

const showDialog = (data, opts) => {
  if (dialogComponent.value) {
    $q.dialog({
      component: dialogComponent.value,
      componentProps: {
        data,
        ...opts,
      },
    })
      .onOk(() => {})
      .onCancel(() => {})
      .onDismiss(() => {
        dialogStore.hide(data);
      });
  } else {
    console.error("Dialog component is not set");
  }
};

const switchDialog = (data) => {
  let opts = {};
  switch (data.type) {
    case "cartConfirmRemove":
      dialogComponent.value = CartConfirmRemoveDialog;
      break;
    case "imageZoom":
      dialogComponent.value = ImageZoomDialog;
      break;
    case "search":
      dialogComponent.value = SearchDialog;
      opts = {
        position: "top",
      };
      break;
    case "product":
      dialogComponent.value = ProductPreviewDialog;
      break;
    default:
      dialogComponent.value = DialogTemplate;
      break;
  }
  showDialog(data, opts);
};

watch(
  dialogs,
  (newDialogs) => {
    if (newDialogs && newDialogs.length > dialogCount.value) {
      show(newDialogs[newDialogs.length - 1]);
    }
    dialogCount.value = newDialogs ? newDialogs.length : 0;
  },
  { deep: true }
);
</script>
